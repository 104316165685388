import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { setBreadcrumps } from "../../actions";
import Table from "../../components/Table";
import Header from "../../components/Header";
import axios from "../../api";
import Backdrop from "../../components/Backdrop";
import "./graduated.css";
import Filters from "../../components/Filters";
import Download from "@material-ui/icons/CloudDownload";
import Swal from "sweetalert2";
import { encrypt } from "../../utils/crypt";

import { AddCircle } from "@material-ui/icons";

function CertificateList(props) {
  const { page, rowsPerPage, setBreadcrumps, permission, token, userId } =
    props;
  const history = useHistory();
  const [graduated, setGraduated] = useState([]);
  const [filtro, setFiltro] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [filtros, setFiltros] = useState({});

  useEffect(() => {
    if (permission.includes(1)) {
      getGraduated();
      setBreadcrumps([
        { name: "Egresados" },
        { name: "Solicitud de validaciones" },
      ]);
    } else {
      history.push("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setGraduated([]);
    setFiltro([]);
    getGraduated();
    window.scrollTo(0, document.body.scrollHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filtros, rowsPerPage]);

  const getGraduated = async () => {
    setLoading(true);
    const { data } = await axios.post(
      `/graduated/getGraduatedTitles`,
      { page, rowsPerPage, where: { ...filtros, estado: 1 } },
      {
        headers: { "access-token": token },
      }
    );
    setGraduated(data.graduated?.graduatedTitles);
    setFiltro(data.graduated?.graduatedTitles);
    setTotal(data.graduated?.count);
    setLoading(false);
  };

  const createRequest = async (e) => {
    setLoading(true);
    const { data } = await axios.post(
      `/certificate/`,
      {
        vp_estado_solicitud: 229,
        id_solicitante: userId,
        id_egresado_titulo: e,
      },
      {
        headers: { "access-token": token },
      }
    );
    if (data?.certificateId) {
      Swal.fire({ icon: "success", text: "Solicitado correctamente" });
    } else {
      Swal.fire({ icon: "error", text: "Error al solicitar" });
    }
    setFiltros({ ...filtros });
    setLoading(false);
  };

  const handleClick = async (e, id, action, certificateId = null) => {
    setLoading(true);
    switch (action) {
      case "generate":
        window.open(`/acta/${encrypt(id)}`);
        break;
      case "request":
        createRequest(id);
        break;
      case "downloadCertificate":
        await axios.put(
          `/certificate/${certificateId}`,
          { vp_estado_solicitud: 232 },
          {
            headers: { "access-token": token },
          }
        );
        window.open(`/certificado/${encrypt(id)}/${encrypt(certificateId)}`);
        getGraduated();
        break;
      default:
        break;
    }
    setLoading(false);
  };

  return (
    <Paper elevation={0} className="pw-2">
      <Header tableName={"graduated"} items={graduated} setItems={setFiltro} />
      <Filters
        setFiltros={setFiltros}
        actaFilter={true}
        dateName="graduación"
      />
      <Table columns={columns} rows={filtro} count={total}>
        <TableBody>
          {filtro?.length > 0 ? (
            <>
              {filtro.map((row, index) => (
                <TableRow key={`row${row.id}`}>
                  {row?.solicitudes?.length > 0 ? (
                    row?.solicitudes[0].vp_estado_solicitud === 230 ? (
                      <TableCell align="center">
                        <Tooltip title="Descargar certificado">
                          <IconButton
                            aria-label="read"
                            onClick={(e) =>
                              handleClick(
                                e,
                                row.id,
                                "downloadCertificate",
                                row?.solicitudes[0].id
                              )
                            }
                          >
                            <Download />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    ) : (
                      <TableCell align="center">En aprobación</TableCell>
                    )
                  ) : (
                    <TableCell align="center">
                      <Tooltip title="Solicitar certificado">
                        <IconButton
                          aria-label="read"
                          onClick={(e) => handleClick(e, row.id, "request")}
                        >
                          <AddCircle />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                  <TableCell align="center">
                    {row?.egresados?.identificacion}
                  </TableCell>
                  <TableCell align="center">
                    {`${row?.egresados?.primer_nombre} ${row?.egresados?.segundo_nombre}`.trim()}
                  </TableCell>
                  <TableCell align="center">
                    {`${row.egresados?.primer_apellido} ${row?.egresados?.segundo_apellido}`.trim()}
                  </TableCell>
                  <TableCell align="center">{row?.Titulo?.nombre}</TableCell>
                  <TableCell align="center">
                    {new Date(`${row?.fecha_grado} `).toLocaleDateString(
                      "en-GB"
                    )}
                  </TableCell>
                  <TableCell align="center">{row?.numero_acta}</TableCell>
                  <TableCell align="center">{row?.folio}</TableCell>
                  <TableCell align="center">{row?.libro}</TableCell>
                  <TableCell align="center">{row?.numero_registro}</TableCell>
                  <TableCell align="center">{row?.ubicacion_az}</TableCell>
                  <TableCell align="center">{row?.proyecto_grado}</TableCell>
                  <TableCell align="center">{row?.numero_folio}</TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="15">
                No hay datos registrados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Backdrop loading={loading} />
    </Paper>
  );
}

const columns = [
  {
    id: "acciones",
    label: "Acciones",
    align: "center",
    minWidth: 10,
  },
  {
    id: "document",
    label: "Documento",
    minWidth: 100,
    align: "center",
  },
  {
    id: "name",
    label: "Nombres",
    minWidth: 150,
    align: "center",
  },
  {
    id: "lastname",
    label: "Apellidos",
    minWidth: 150,
    align: "center",
  },
  {
    id: "title",
    label: "Título",
    minWidth: 100,
    align: "center",
  },

  {
    id: "graduationDate",
    label: "Fecha de Graduación",
    minWidth: 150,
    align: "center",
  },
  {
    id: "minuteNumber",
    label: "Número de acta",
    minWidth: 150,
    align: "center",
  },
  {
    id: "sheet",
    label: "Folio",
    minWidth: 100,
    align: "center",
  },
  {
    id: "book",
    label: "Libro",
    minWidth: 100,
    align: "center",
  },
  {
    id: "recordNumber",
    label: "Número de registro",
    minWidth: 100,
    align: "center",
  },
  {
    id: "azLocation",
    label: "Ubicación AZ",
    minWidth: 100,
    align: "center",
  },
  {
    id: "project",
    label: "Proyecto de grado",
    minWidth: 100,
    align: "center",
  },
  {
    id: "sheetNumber",
    label: "Número folio",
    minWidth: 100,
    align: "center",
  },
];

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 9)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToProps = {
  setBreadcrumps,
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificateList);
