import React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import SignIn from "../containers/SignIn";
import Layout from "../containers/Layout";
import Actions from "../containers/settings/Actions";
import Sessions from "../containers/settings/Sessions";
import Modules from "../containers/settings/modules/Modules";
import ModulesForm from "../containers/settings/modules/Form";
import UserGroups from "../containers/settings/userGroups/UserGroups";
import FormUserGroups from "../containers/settings/userGroups/Form";
import Users from "../containers/settings/users/Users";
import UsersForm from "../containers/settings/users/Form";
import Parameters from "../containers/settings/parameters/Parameters";
import ParametersForm from "../containers/settings/parameters/Form";
import Permissions from "../containers/settings/permissions/Permissions";
import PermissionsForm from "../containers/settings/permissions/Form";
import EditProfile from "../containers/accounts/EditProfile";
import ChangePasswordProfile from "../containers/accounts/ChangePassProfile";
import ForgetPassword from "../containers/accounts/ForgetPass";
import ChangePassword from "../containers/accounts/ChangePass";
import GraduatedList from "../containers/graduated/List";
import GraduatedForm from "../containers/graduated/Form";
import CertificatesList from "../containers/certificates/Actas";
import Reports from "../containers/reports/Reports";
import RequestsList from "../containers/requests/List";
import ValidationList from "../containers/certificates/Validaciones";
import ProgramsList from "../containers/programs/List";
import ProgramForm from "../containers/programs/Form";
import integrationList from "../containers/integrations/integrationList";
import logsList from "../containers/logs/logsList";
import Minute from "../containers/certificates/Minute";
import Certificate from "../containers/certificates/Certificate";

const SwitchApp = () => {
  const location = useLocation();

  return (
    <BrowserRouter>
      <Switch>
        {/* Ruta principal */}
        <Route exact path="/">
          <Redirect to="sign-in" />
        </Route>
        <Switch location={location} key={location.pathname}>
          <Route path="/sign-in" component={SignIn} />
          <Route path="/account/forget" exact component={ForgetPassword} />
          <Route
            path="/account/forget/:userId"
            exact
            component={ChangePassword}
          />

          <Route path="/acta/:id" exact component={Minute} />
          <Route
            path="/certificado/:id/:certificateId"
            exact
            component={Certificate}
          />
          <Layout>
            <Route path="/profile/edit/:id" component={EditProfile} />
            <Route
              path="/profile/password/:id"
              component={ChangePasswordProfile}
            />

            {/* Acciones */}
            <Route path="/actions" exact component={Actions} />
            {/* Sesiones */}
            <Route path="/sessions" exact component={Sessions} />

            {/* Módulos */}
            <Route path="/modules" exact component={Modules} />
            <Route path="/modules/create" component={ModulesForm} />
            <Route path="/modules/edit/:id" component={ModulesForm} />

            {/* Grupos de usuario */}
            <Route path="/userGroups" exact component={UserGroups} />
            <Route path="/userGroups/create" component={FormUserGroups} />
            <Route path="/userGroups/edit/:id" component={FormUserGroups} />

            {/* Usuarios */}
            <Route path="/users" exact component={Users} />
            <Route path="/users/create" component={UsersForm} />
            <Route path="/users/edit/:id" component={UsersForm} />

            {/* Parámetros */}
            <Route path="/parameters" exact component={Parameters} />
            <Route path="/parameters/create" component={ParametersForm} />
            <Route path="/parameters/edit/:id" component={ParametersForm} />

            {/* Permisos */}
            <Route path="/permissions" exact component={Permissions} />
            <Route path="/permissions/create" component={PermissionsForm} />
            <Route path="/permissions/edit/:id" component={PermissionsForm} />

            {/* Graduados */}
            <Route path="/graduated" exact component={GraduatedList} />
            <Route path="/graduated/edit/:id" component={GraduatedForm} />
            <Route path="/graduated/create" component={GraduatedForm} />

            {/* Certificados */}
            <Route path="/certificates" exact component={CertificatesList} />
            <Route path="/validations" exact component={ValidationList} />

            {/* Reportes */}
            <Route path="/reports" exact component={Reports} />
            {/* Solicitudes */}
            <Route path="/requests" exact component={RequestsList} />

            {/* Programas */}
            <Route path="/programs" exact component={ProgramsList} />
            <Route path="/programs/create" component={ProgramForm} />
            <Route path="/programs/edit/:id" component={ProgramForm} />

            {/* Integraciones */}
            <Route path="/integrations" exact component={integrationList} />
            <Route path="/logs" exact component={logsList} />
          </Layout>
        </Switch>
      </Switch>
    </BrowserRouter>
  );
};

export default SwitchApp;
