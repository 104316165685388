import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  makeStyles,
  Grid,
  FormControl,
  TextField,
  Paper,
  Typography,
  MenuItem,
  Select,
  Button,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "../api";
import { setPage } from "../actions";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/es";
moment.locale("es");

const Filter = (props) => {
  const { setPage, token, setFiltros, actaFilter=false, dateName } = props;
  const classes = useStyles();
  const [ciudades, setCiudades] = useState([]);
  const [sexoVp, setSexoVp] = useState([]);
  const [tipoIdentificacionVp, setTipoIdentificacionVp] = useState([]);
  const [elaboradoEnVp, setElaboradoEnVp] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [filtro, setFiltro] = useState({
    documento: "",
    primer_nombre: "",
    segundo_nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    codigo_icfes: "",
    num_libreta_militar: "",
    num_distrito_militar: "",
    correo: "",
    vp_elaborado_en: "",
    vp_sexo: "",
    id_ciudad_expedicion: "",
    vp_tipo_documento: "",
    id_titulo: "",
    id_programa: "",
    numero_acta: "",
  });
  const [fecha_inicial, setFecha_inicial] = useState(null);
  const [fecha_final, setFecha_final] = useState(null);

  useEffect(() => {
    getParameters();
    getCities();
    getPrograms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getParameters = async () => {
    const { data } = await axios.post(
      `/parameter/getParameters`,
      { id: [2, 4, 3, 5] },
      {
        headers: { "access-token": token },
      }
    );
    setSexoVp(
      data?.parameters[1]?.valoresParametros?.sort((a, b) =>
        a.orden < b.orden ? -1 : 1
      )
    );
    setElaboradoEnVp(
      data?.parameters[2]?.valoresParametros?.sort((a, b) =>
        a.orden < b.orden ? -1 : 1
      )
    );
    setTipoIdentificacionVp(
      data?.parameters[3]?.valoresParametros?.sort((a, b) =>
        a.orden < b.orden ? -1 : 1
      )
    );
  };


  const getPrograms = async () => {
    const { data } = await axios.post(
      `/program/getPrograms`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setPrograms(data.programs);
  };

  const getCities = async () => {
    const { data } = await axios.post(
      `/city/getCities`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setCiudades(data.citys);
  };

  const getData = async () => {
    setPage(0);
    let news = { ...filtro };
    if (fecha_inicial !== null) {
      news.fecha_inicial = fecha_inicial;
    }
    if (fecha_final !== null) {
      news.fecha_final = fecha_final;
    }
    setFiltros({ ...news });
  };

  const handleChange = (e) => {
    setFiltro({
      ...filtro,
      [e.target.name]: e.target.value,
    });
  };

  const handleClear = async () => {
    setPage(0);
    setFiltro({
      documento: "",
      nombre: "",
      codigo_icfes: "",
      num_libreta_militar: "",
      num_distrito_militar: "",
      correo: "",
      vp_elaborado_en: "",
      vp_sexo: "",
      id_ciudad_expedicion: "",
      vp_tipo_documento: "",
      id_titulo: "",
      fecha_inicial: "",
      fecha_final: "",
      id_programa: "",
      acta: "",
    });
    setFiltros({
      documento: "",
      nombre: "",
      codigo_icfes: "",
      num_libreta_militar: "",
      num_distrito_militar: "",
      correo: "",
      vp_elaborado_en: "",
      vp_sexo: "",
      id_ciudad_expedicion: "",
      vp_tipo_documento: "",
      id_titulo: "",
      fecha_inicial: "",
      fecha_final: "",
      id_programa: "",
      acta: "",
    });
    setFecha_inicial(null);
    setFecha_final(null);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Typography style={{maxWidth:"100%"}}>
              Fecha inicial {dateName || ""}
              <br />
              <DatePicker
                autoOk
                disableFuture
                clearable
                cancelLabel="Cancelar"
                format="DD/MM/YYYY"
                label=""
                value={fecha_inicial}
                onChange={setFecha_inicial}
                maxDate={fecha_final || new Date()}
                inputProps={{ style: { textAlign: "center" } }}
              />
            </Typography>
          </MuiPickersUtilsProvider>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Typography style={{maxWidth:"100%"}}>
              Fecha final {dateName || ""}
              <br />
              <DatePicker
                autoOk
                clearable
                cancelLabel="Cancelar"
                format="DD/MM/YYYY"
                label=""
                value={fecha_final}
                onChange={setFecha_final}
                minDate={fecha_inicial || new Date()}
                inputProps={{ style: { textAlign: "center" } }}
              />
            </Typography>
          </MuiPickersUtilsProvider>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <Typography style={{maxWidth:"100%"}}>
            Documento
            <br />
            <TextField
              name="documento"
              value={filtro?.documento}
              onChange={handleChange}
              inputProps={{ style: { textAlign: "center" } }}
            />
          </Typography>
        </Paper>
      </Grid>
      {actaFilter && <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <Typography style={{maxWidth:"100%"}}>
            Número acta
            <br />
            <TextField
              name="acta"
              value={filtro?.acta}
              onChange={handleChange}
              inputProps={{ style: { textAlign: "center" } }}
            />
          </Typography>
        </Paper>
      </Grid>}
      <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <Typography style={{maxWidth:"100%"}}>
            Primer nombre
            <br />
            <TextField
              name="primer_nombre"
              value={filtro?.primer_nombre}
              onChange={handleChange}
              inputProps={{ style: { textAlign: "center" } }}
            />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <Typography style={{maxWidth:"100%"}}>
            Segundo nombre
            <br />
            <TextField
              name="segundo_nombre"
              value={filtro?.segundo_nombre}
              onChange={handleChange}
              inputProps={{ style: { textAlign: "center" } }}
            />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <Typography style={{maxWidth:"100%"}}>
            Primer apellido
            <br />
            <TextField
              name="primer_apellido"
              value={filtro?.primer_apellido}
              onChange={handleChange}
              inputProps={{ style: { textAlign: "center" } }}
            />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <Typography style={{maxWidth:"100%"}}>
            Segundo apellido
            <br />
            <TextField
              name="segundo_apellido"
              value={filtro?.segundo_apellido}
              onChange={handleChange}
              inputProps={{ style: { textAlign: "center" } }}
            />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <Typography style={{maxWidth:"100%"}}>
            Código icfes
            <br />
            <TextField
              name="codigo_icfes"
              value={filtro?.codigo_icfes}
              onChange={handleChange}
              inputProps={{ style: { textAlign: "center" } }}
            />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <Typography style={{maxWidth:"100%"}}>
            Número libreta militar
            <br />
            <TextField
              name="num_libreta_militar"
              value={filtro?.num_libreta_militar}
              onChange={handleChange}
              inputProps={{ style: { textAlign: "center" } }}
            />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <Typography style={{maxWidth:"100%"}}>
            Número distrito militar
            <br />
            <TextField
              name="num_distrito_militar"
              value={filtro?.num_distrito_militar}
              onChange={handleChange}
              inputProps={{ style: { textAlign: "center" } }}
            />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <Typography style={{maxWidth:"100%"}}>
            Correo
            <br />
            <TextField
              name="correo"
              value={filtro?.correo}
              onChange={handleChange}
              inputProps={{ style: { textAlign: "center" } }}
            />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <Typography style={{maxWidth:"100%"}}>
            Elaborado en
            <br />
            <FormControl style={{ marginLeft: ".5em", maxWidth:"80%" }}>
              <Select
                value={filtro.vp_elaborado_en}
                onChange={handleChange}
                name="vp_elaborado_en"
                style={{ minWidth: "10em" }}
              >
                <MenuItem value="">
                  <em>Seleccione una opción</em>
                </MenuItem>
                {elaboradoEnVp.map((data) => {
                  return (
                    <MenuItem key={`parameter-${data.id}`} value={data.id}>
                      {`${data.valor_parametro}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <Typography style={{maxWidth:"100%"}}>
            Sexo
            <br />
            <FormControl style={{ marginLeft: ".5em", maxWidth:"80%" }}>
              <Select
                value={filtro.vp_sexo}
                onChange={handleChange}
                name="vp_sexo"
                style={{ minWidth: "10em" }}
              >
                <MenuItem value="">
                  <em>Seleccione una opción</em>
                </MenuItem>
                {sexoVp.map((data) => {
                  return (
                    <MenuItem key={`parameter-${data.id}`} value={data.id}>
                      {`${data.valor_parametro}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <Typography style={{maxWidth:"100%"}}>
            Tipo documento
            <br />
            <FormControl style={{ marginLeft: ".5em", maxWidth:"80%" }}>
              <Select
                value={filtro.vp_tipo_documento}
                onChange={handleChange}
                name="vp_tipo_documento"
                style={{ minWidth: "10em" }}
              >
                <MenuItem value="">
                  <em>Seleccione una opción</em>
                </MenuItem>
                {tipoIdentificacionVp.map((data) => {
                  return (
                    <MenuItem key={`parameter-${data.id}`} value={data.id}>
                      {`${data.valor_parametro}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <Typography style={{maxWidth:"100%"}}>
            Programa
            <br />
            <FormControl style={{ marginLeft: ".5em", maxWidth:"80%" }}>
              <Select
                value={filtro.id_programa}
                onChange={handleChange}
                name="id_programa"
                style={{ minWidth: "10em" }}
              >
                <MenuItem value="">
                  <em>Seleccione una opción</em>
                </MenuItem>
                {programs
                  .sort((a, b) => (`${a.nombre}` < `${b.nombre}` ? -1 : 1))
                  .map((data) => {
                    return (
                      <MenuItem key={`parameter-${data.id}`} value={data.id}>
                        {`${data.nombre}`}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper className={classes.card}>
          <Typography style={{maxWidth:"100%"}}>
            Ciudad expedición
            <br />
            <FormControl style={{ marginLeft: ".5em", maxWidth:"80%" }}>
              <Select
                value={filtro.id_ciudad_expedicion}
                onChange={handleChange}
                name="id_ciudad_expedicion"
                style={{ minWidth: "10em" }}
              >
                <MenuItem value="">
                  <em>Seleccione una opción</em>
                </MenuItem>
                {ciudades
                  .sort((a, b) => (`${a.nombre}` < `${b.nombre}` ? -1 : 1))
                  .map((data) => {
                    return (
                      <MenuItem key={`parameter-${data.id}`} value={data.id}>
                        {`${data.nombre}`}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} align="center">
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={getData}
        >
          Filtrar
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => handleClear()}
        >
          Limpiar
        </Button>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#9B9B9B",
  },
  submit: {
    borderRadius: "10px",
  },
  container__input_root: {
    borderRadius: "10px",
  },
  card: {
    border: "0.1px solid",
    borderColor: "gray",
    padding: ".2em 0em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    borderRadius: "10px",
    marginLeft: ".5em",
  },
  button: {
    margin: "0.5em",
    padding: ".5em 3em",
    borderRadius: "10px",
  },
}));

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 8)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToProps = { setPage };

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
