import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { setBreadcrumps } from "../../actions";
import { encrypt } from "../../utils/crypt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import Table from "../../components/Table";
import Header from "../../components/Header";
import axios from "../../api";
import Backdrop from "../../components/Backdrop";
import Modal from "../../components/Modal";
import "./graduated.css";
import Filters from "../../components/Filters";
import DeleteIcon from "@material-ui/icons/Cancel";
import Swal from "sweetalert2";
function GraduatedList(props) {
  const { page, rowsPerPage, setBreadcrumps, permission, token } = props;
  const history = useHistory();
  const [graduated, setGraduated] = useState([]);
  const [filtro, setFiltro] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sexoVp, setSexoVp] = useState([]);
  const [tipoIdentificacionVp, setTipoIdentificacionVp] = useState([]);
  const [elaboradoEnVp, setElaboradoEnVp] = useState([]);
  const [nivelEducativoVp, setNivelEducativoVp] = useState([]);
  const [total, setTotal] = useState(0);
  const [filtros, setFiltros] = useState({});

  useEffect(() => {
    if (permission.includes(1)) {
      getGraduated();
      getParameters();
      setBreadcrumps([{ name: "Egresados" }, { name: "Gestionar egresados" }]);
    } else {
      history.push("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setGraduated([]);
    setFiltro([]);
    getGraduated();

    window.scrollTo(0, document.body.scrollHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filtros]);

  const getGraduated = async () => {
    setLoading(true);
    const { data } = await axios.post(
      `/graduated/getGraduated`,
      { page, rowsPerPage, where: filtros },
      {
        headers: { "access-token": token },
      }
    );
    setGraduated(data.graduated?.graduated);
    setFiltro(data.graduated?.graduated);
    setTotal(data.graduated?.count);
    setLoading(false);
  };
  const getParameters = async () => {
    const { data } = await axios.post(
      `/parameter/getParameters`,
      { id: [2, 4, 3, 5] },
      {
        headers: { "access-token": token },
      }
    );
    setNivelEducativoVp(
      data?.parameters[0]?.valoresParametros?.sort((a, b) =>
        a.orden < b.orden ? -1 : 1
      )
    );
    setSexoVp(
      data?.parameters[1]?.valoresParametros?.sort((a, b) =>
        a.orden < b.orden ? -1 : 1
      )
    );
    setElaboradoEnVp(
      data?.parameters[2]?.valoresParametros?.sort((a, b) =>
        a.orden < b.orden ? -1 : 1
      )
    );
    setTipoIdentificacionVp(
      data?.parameters[3]?.valoresParametros?.sort((a, b) =>
        a.orden < b.orden ? -1 : 1
      )
    );
  };

  const parseVp = (array, item) => {
    let _value = null;
    array.forEach((e) => {
      if (`${e.id}` === `${item}`) _value = e.valor_parametro;
    });
    return _value;
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case "edit":
        history.push(`/graduated/edit/${encrypt(id)}`);
        break;
      case "create":
        history.push(`/graduated/create`);
        break;
      default:
        break;
    }
  };

  const dataToExcel = async () => {
    // eslint-disable-next-line array-callback-return
    setLoading(true);
    const { data } = await axios.post(
      `/graduated/getGraduated`,
      { page, rowsPerPage, where: filtros },
      {
        headers: { "access-token": token },
      }
    );
    const arrayExcel = [];
    await data?.graduated?.graduated?.forEach((item) => {
      item?.titulos?.forEach((e) => {
        arrayExcel.push({
          Identificación: item.identificacion || "",
          Nombres: `${item.primer_nombre} ${item.segundo_nombre} ${item.primer_apellido} ${item.segundo_apellido}`,
          "Tipo identificación": parseVp(
            tipoIdentificacionVp,
            item.vp_tipo_identificacion
          ),
          "Elaborado en": parseVp(elaboradoEnVp, item?.vp_elaborado_en),
          Sexo: parseVp(sexoVp, item?.vp_sexo),
          Ciudad: item?.ciudades?.nombre,
          "Fecha nacimiento": item?.fecha_nacimiento,
          "Código ICFES": item?.codigo_icfes,
          "Código tercero": item?.externalcode,
          "Fecha creación egresado": item?.createdAt?.substring(0, 10),
          Título: e?.Titulo?.nombre,
          "Nivel educativo": parseVp(
            nivelEducativoVp,
            e?.Titulo?.vp_nivel_educativo
          ),
          "Código snies": e.codigo_snies,
          "Procódigo snies": e.procodigo_snies,
          "Fecha grado": e?.fecha_grado,
          "Número acta": e?.numero_acta,
          Folio: e?.folio,
          Libro: e?.libro,
          "Número registro": e?.numero_registro,
          "Ubicación AZ": e?.ubicacion_az,
          "Proyecto de grado": e?.proyecto_grado,
          "Número folio": e?.numero_folio,
        });
      });
    });
    setLoading(false);
    return arrayExcel;
  };

  const deleteGraduated = async (id) => {
    Swal.fire({
      icon: "warning",
      text: "¿Está seguro de querer eliminar a este graduado?",
      showConfirmButton: true,
      showCancelButton: true,
    }).then(async (res) => {
      if (res.isConfirmed) {
        const { data } = await axios.delete(`/graduated/${id}`, {
          headers: { "access-token": token },
        });
        if (data?.graduatedDeleted?.success) {
          Swal.fire({
            icon: "success",
            text: "Se eliminó correctamente al graduado",
          });
        } else {
          Swal.fire({ icon: "error", text: "Error al actualizar estado" });
        }
        getGraduated();
      }
      setLoading(false);
    });
  };

  return (
    <Paper elevation={0} className="pw-2">
      <Header
        search={false}
        button={permission.includes(2) ? true : false}
        exportButton={permission.includes(5) ? true : false}
        dataToExcel={{ fileName: "Egresados" }}
        dataToExcelF={dataToExcel}
        buttonText={"Crear"}
        buttonRoute={"/graduated/create"}
        tableName={"graduated"}
        items={graduated}
        setItems={setFiltro}
      />
      <Filters setFiltros={setFiltros} dateName="nacimiento" />
      <Table columns={columns} rows={filtro} count={total}>
        <TableBody>
          {filtro?.length > 0 ? (
            <>
              {filtro.map((row, index) => (
                <TableRow key={`row${index}`}>
                  <TableCell align="center">
                    <Modal
                      nivelEducativoVp={nivelEducativoVp}
                      parseVp={parseVp}
                      graduatedData={row}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {permission.includes(3) && (
                      <Tooltip title="Editar">
                        <IconButton
                          aria-label="edit"
                          onClick={(e) => handleClick(e, row.id, "edit")}
                        >
                          <FontAwesomeIcon icon={faEdit} size={"xs"} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {permission.includes(4) && (
                      <Tooltip title="Eliminar">
                        <IconButton
                          aria-label="edit"
                          onClick={(e) => deleteGraduated(row.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell align="center">{row?.identificacion}</TableCell>
                  <TableCell align="center">
                    {`${row.primer_nombre} ${row?.segundo_nombre}`.trim()}
                  </TableCell>
                  <TableCell align="center">
                    {`${row.primer_apellido} ${row?.segundo_apellido}`.trim()}
                  </TableCell>
                  <TableCell align="center">
                    {parseVp(tipoIdentificacionVp, row.vp_tipo_identificacion)}
                  </TableCell>
                  <TableCell align="center">
                    {parseVp(elaboradoEnVp, row?.vp_elaborado_en)}
                  </TableCell>
                  <TableCell align="center">
                    {parseVp(sexoVp, row?.vp_sexo)}
                  </TableCell>
                  <TableCell align="center">{row?.ciudades?.nombre}</TableCell>
                  <TableCell align="center">
                    {new Date(`${row?.fecha_nacimiento}`).toLocaleDateString(
                      "en-GB"
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row?.num_libreta_militar}
                  </TableCell>
                  <TableCell align="center">
                    {row?.num_distrito_militar}
                  </TableCell>
                  <TableCell align="center">{row?.codigo_icfes}</TableCell>
                  <TableCell align="center">
                    {row?.createdAt?.substring(0, 10)}
                  </TableCell>
                  <TableCell align="center">{row?.codigo_terceros}</TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="15">
                No hay datos registrados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Backdrop loading={loading} />
    </Paper>
  );
}

const columns = [
  {
    id: "acciones",
    label: "Acciones",
    align: "center",
    minWidth: 10,
    colSpan: 3,
  },
  {
    id: "document",
    label: "Documento",
    minWidth: 100,
    align: "center",
  },
  {
    id: "name",
    label: "Nombres",
    minWidth: 150,
    align: "center",
  },
  {
    id: "lastname",
    label: "Apellidos",
    minWidth: 150,
    align: "center",
  },
  {
    id: "idtype",
    label: "Tipo Identificación",
    minWidth: 100,
    align: "center",
  },
  {
    id: "elaborated",
    label: "Elaborado en",
    minWidth: 100,
    align: "center",
  },
  {
    id: "sex",
    label: "Sexo",
    minWidth: 100,
    align: "center",
  },
  {
    id: "city",
    label: "Ciudad",
    minWidth: 100,
    align: "center",
  },
  {
    id: "birthdate",
    label: "Fecha de nacimiento",
    minWidth: 100,
    align: "center",
  },
  {
    id: "militarycardnumber",
    label: "Número de libreta militar",
    minWidth: 100,
    align: "center",
  },
  {
    id: "militarydistrictnumber",
    label: "Número de distrito militar",
    minWidth: 100,
    align: "center",
  },
  {
    id: "icfescode",
    label: "Código ICFES",
    minWidth: 100,
    align: "center",
  },
  {
    id: "creationdate",
    label: "Fecha creación",
    minWidth: 100,
    align: "center",
  },
  {
    id: "externalcode",
    label: "Código terceros",
    minWidth: 100,
    align: "center",
  },
];

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 8)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToProps = {
  setBreadcrumps,
};

export default connect(mapStateToProps, mapDispatchToProps)(GraduatedList);
