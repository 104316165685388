import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Swal from "sweetalert2";

const TitleModalForm = ({
  editData = null,
  setTitles,
  titulos,
  titles,
  idx,
  programs
}) => {
  const [open, setOpen] = useState(false);
  const [fakeTitles, setFakeTitles] = useState(titulos)
  const [program, setProgram] = useState(editData?.Titulo?.id_programas || "")
  const parseVp = (array, item) => {
    let _value = null;
    array.forEach((e) => {
      if (`${e.id}` === `${item}`) _value = e;
    });
    return _value;
  };

  useEffect(() => {
    setFakeTitles(titulos.filter((e) => e.id_programas === program))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program])
  
  const [form, setForm] = useState({
    id: editData?.id,
    estado: editData?.estado || "",
    id_titulos: editData?.Titulo?.id || "",
    fecha_grado: editData?.fecha_grado
      ? new Date(editData?.fecha_grado)
      : null,
    numero_acta: editData?.numero_acta || "",
    folio: editData?.folio || "",
    libro: editData?.libro || "",
    numero_registro: editData?.numero_registro || "",
    ubicacion_az: editData?.ubicacion_az || "",
    proyecto_grado: editData?.proyecto_grado || "",
    numero_folio: editData?.numero_folio || "",
    codigo_snies: editData?.codigo_snies || "",
    procodigo_snies: editData?.procodigo_snies || ""
  });

  const handleAction = () => {
    if (`${form.id_titulos}`.trim() !== "") {
      if ((form.id !== null && form.id !== undefined) || (idx !== null && idx !== undefined)) {
        let titulo = parseVp(titulos, form.id_titulos);
        let add = form;
        add.Titulo = titulo;
        add.fecha_grado = add.fecha_grado
          .toLocaleString("sv", { timeZoneName: "short" })
          .substring(0, 10);
        let titulosActual = titles;
        titulosActual[idx] = add;
        setTitles([...titulosActual]);
        Swal.fire({
          text: "Actualizado correctamente",
          icon: "success",
        });
        setOpen(false);
      } else {
        let titulo = parseVp(titulos, form.id_titulos);
        let add = {...form};
        add.Titulo = titulo;
        add.fecha_grado = add.fecha_grado
          .toLocaleString("sv", { timeZoneName: "short" })
          .substring(0, 10);
        let newTitles = [...titles, add];
        setTitles(newTitles);
        Swal.fire({
          text: "Se añadió correctamente",
          icon: "success",
        });
        setOpen(false);
      }
    } else {
      Swal.fire({
        text: "Se debe al menos seleccionar un título y una fecha para poder agregar un título de egresado",
        icon: "error",
      });
    }
  };
  const clear = () => {
    setProgram(editData?.Titulo?.id_programas || "")
    setForm({
      id: editData?.id,
      id_titulos: editData?.Titulo?.id || "",
      estado: editData?.estado !== undefined ? editData?.estado:"",
      fecha_grado: editData?.fecha_grado
        ? new Date(editData?.fecha_grado)
        : null,
      numero_acta: editData?.numero_acta || "",
      folio: editData?.folio || "",
      libro: editData?.libro || "",
      numero_registro: editData?.numero_registro || "",
      ubicacion_az: editData?.ubicacion_az || "",
      proyecto_grado: editData?.proyecto_grado || "",
      numero_folio: editData?.numero_folio || "",
      codigo_snies: editData?.codigo_snies || "",
      procodigo_snies: editData?.procodigo_snies || ""
    });
  };


  const handleDateChange = (e, date) => {
    let _new = { ...form };
    _new[date] = e?._d;
    setForm(_new);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const classes = useStyles();

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value.toString(),
    });
  };

  return (
    <>
      {editData ? (
        <Tooltip title="Editar">
          <IconButton
            aria-label="edit"
            onClick={(e) => {
              clear();
              setOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faEdit} size={"xs"} />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={(e) => {
            clear();
            setOpen(true);
          }}
        >
          Añadir título
        </Button>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <DialogContent align="center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {editData ? "Editar" : "Crear"} título de egresado
          </Typography>
          <br />
          <form>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Número acta"
                  name="numero_acta"
                  value={form.numero_acta}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Folio"
                  name="folio"
                  value={form.folio}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Libro"
                  name="libro"
                  value={form.libro}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Número registro"
                  name="numero_registro"
                  value={form.numero_registro}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Ubicación A-Z"
                  name="ubicacion_az"
                  value={form.ubicacion_az}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Número folio"
                  name="numero_folio"
                  value={form.numero_folio}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Código SNIES"
                  name="codigo_snies"
                  value={form.codigo_snies}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Procódigo SNIES"
                  name="procodigo_snies"
                  value={form.procodigo_snies}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Proyecto de grado"
                  name="proyecto_grado"
                  value={form.proyecto_grado}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <KeyboardDatePicker
                  fullWidth
                  clearable
                  inputVariant="outlined"
                  label="Fecha de grado"
                  name="fecha_grado"
                  format="DD/MM/yyyy"
                  value={form.fecha_grado}
                  onChange={(e) => {
                    handleDateChange(e, "fecha_grado");
                  }}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl required fullWidth variant="outlined">
                  <InputLabel id="statusLabel">Estado</InputLabel>
                  <Select
                    labelId="statusLabel"
                    label="Estado"
                    value={form.estado}
                    onChange={handleInput}
                    name="estado"
                    className={classes.container__input_root}
                    align="left"
                  >
                    <MenuItem value="" disabled>
                      <em>Seleccione una opción</em>
                    </MenuItem>
                    <MenuItem value={0}>Inactivo</MenuItem>
                    <MenuItem value={1}>Activo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl required fullWidth variant="outlined">
                  <InputLabel id="titleLabel">Programa</InputLabel>
                  <Select
                    labelId="titleLabel"
                    label="Programa"
                    value={program}
                    onChange={(e) => setProgram(e.target.value)}
                    name="id_programas"
                    className={classes.container__input_root}
                    align="left"
                  >
                    <MenuItem value="" disabled>
                      <em>Seleccione una opción</em>
                    </MenuItem>
                    {programs?.sort((a, b) => (`${a.nombre}` < `${b.nombre}` ? -1 : 1))
                      .map((data) => {
                        return (
                          <MenuItem key={data.id} value={data.id}>
                            {`${data?.nombre}`}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl required fullWidth variant="outlined">
                  <InputLabel id="titleLabel">Título</InputLabel>
                  <Select
                    labelId="titleLabel"
                    label="Título"
                    value={form.id_titulos}
                    onChange={handleInput}
                    name="id_titulos"
                    className={classes.container__input_root}
                    align="left"
                  >
                    <MenuItem value="" disabled>
                      <em>Seleccione una opción</em>
                    </MenuItem>
                    {fakeTitles
                      .sort((a, b) => (`${a.nombre}` < `${b.nombre}` ? -1 : 1))
                      .map((data) => {
                        return (
                          <MenuItem key={data.id} value={data.id}>
                            {`${data?.nombre}`}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
          <div className={classes.containerButton}>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              type="submit"
              onClick={handleAction}
            >
              Guardar
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={handleCancel}
            >
              Cancelar
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1em",
  },
  paper: {
    margin: theme.spacing(2, 2),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    width: "80%",
  },
  containerButton: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginTop: "1em",
      marginBottom: "1em",
    },
  },
  button: {
    margin: "0.5em",
    padding: ".5em 3em",
    borderRadius: "10px",
  },
  container__input_root: {
    borderRadius: "10px",
  },
}));

export default TitleModalForm;
