import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Tooltip,
  Button,
} from "@material-ui/core";
import { setBreadcrumps, setPage } from "../../actions";
import Table from "../../components/Table";
import Header from "../../components/Header";
import axios from "../../api";
import Backdrop from "../../components/Backdrop";
import Swal from "sweetalert2";

function IntegrationList(props) {
  const { page, rowsPerPage, setBreadcrumps, permission, token, setPage } =
    props;
  const history = useHistory();
  const [filtro, setFiltro] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (permission.includes(1)) {
      getIntegrations();
      setBreadcrumps([
        { name: "Integraciones" },
        { name: "Gestionar integraciones" },
      ]);
    } else {
      history.push("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIntegrations = async () => {
    setLoading(true);
    const { data } = await axios.post(
      `/integration/getIntegrations`,
      { estado: [-1, 1, 0] },
      {
        headers: { "access-token": token },
      }
    );
    setFiltro(data.integrations);
    setTotal(data.integrations?.length);
    setLoading(false);
  };

  const updateIntegration = async (id, status) => {
    setLoading(true);
    const { data } = await axios.put(
      `/integration/${id}`,
      { estado: status },
      {
        headers: { "access-token": token },
      }
    );
    if (data?.integrationId) {
      Swal.fire({
        icon: "success",
        text: "Se actualizó correctamente el estado de la integración",
      });
    } else {
      Swal.fire({ icon: "error", text: "Error al actualizar estado" });
    }

    getIntegrations();
    setPage(0);
    setLoading(false);
  };

  return (
    <Paper elevation={0} className="pw-2">
      <Header search={false} />
      <Table columns={columns} rows={filtro} count={total}>
        <TableBody>
          {filtro?.length > 0 ? (
            <>
              {filtro
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`row${index}`}>
                    <TableCell align="center">
                      {permission.includes(3) && (
                        <>
                          {row.estado === 1 ? (
                            <Tooltip title="Desactivar">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={(e) => updateIntegration(row.id, -1)}
                              >
                                Desactivar
                              </Button>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Activar">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={(e) => updateIntegration(row.id, 1)}
                              >
                                Activar
                              </Button>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell align="center">{row?.nombre}</TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="15">
                No hay datos registrados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Backdrop loading={loading} />
    </Paper>
  );
}

const columns = [
  {
    id: "acciones",
    label: "Acciones",
    align: "center",
    minWidth: 10,
  },
  {
    id: "name",
    label: "Nombre",
    align: "center",
    minWidth: 10,
  },
];

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 13)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToProps = {
  setBreadcrumps,
  setPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationList);
