import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setBreadcrumps } from "../../actions";
import {
  makeStyles,
  TextField,
  Button,
  Grid,
  Paper,
  Divider,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { decrypt } from "../../utils/crypt";
import Header from "../../components/Header";
import Swal from "sweetalert2";
import Backdrop from "../../components/Backdrop";
import axios from "../../api";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/es";
import Table from "../../components/Table";
import TitleModalForm from "../../components/TitleModalForm";
import DeleteIcon from "@material-ui/icons/Cancel";

moment.locale("es");

function GraduatedForm(props) {
  const { setBreadcrumps, permission, token } = props;
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [titles, setTitles] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [availableTitles, setAvailableTitles] = useState([]);
  const [form, setForm] = useState({
    primer_nombre: "",
    segundo_nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    vp_sexo: "",
    vp_tipo_identificacion: "",
    id_ciudad_expedicion: "",
    identificacion: "",
    num_libreta_militar: "",
    num_distrito_militar: "",
    codigo_icfes: "",
    correo_personal: "",
    estado: 1,
    fecha_nacimiento: null,
    vp_elaborado_en: "",
    email: "",
    codigo_terceros: "",
  });
  const [sexoVp, setSexoVp] = useState([]);
  const [tipoIdentificacionVp, setTipoIdentificacionVp] = useState([]);
  const [elaboradoEnVp, setElaboradoEnVp] = useState([]);
  const [nivelEducativoVp, setNivelEducativoVp] = useState([]);
  const [ciudades, setCiudades] = useState([]);

  useEffect(() => {
    getParameters();
    getCities();
    getTitles();
    getPrograms();
    if (permission.includes(2) || permission.includes(3)) {
      setBreadcrumps([
        { name: "Egresados", route: "/graduated" },
        { name: props?.match?.params?.id ? "Editar" : "Crear" },
      ]);
      props?.match?.params?.id && getGraduated();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGraduated = async () => {
    try {
      const id = decrypt(props.match.params.id);
      const { data } = await axios.get(`/graduated/${id}`, {
        headers: { "access-token": token },
      });
      let newForm = {
        primer_nombre: data.graduated?.primer_nombre,
        segundo_nombre: data.graduated?.segundo_nombre,
        primer_apellido: data.graduated?.primer_apellido,
        segundo_apellido: data.graduated?.segundo_apellido,
        vp_sexo: data.graduated?.vp_sexo || "",
        vp_tipo_identificacion: data.graduated?.vp_tipo_identificacion || "",
        id_ciudad_expedicion: data.graduated?.ciudades?.id || "",
        identificacion: data.graduated?.identificacion,
        num_libreta_militar: data.graduated?.num_libreta_militar,
        num_distrito_militar: data.graduated?.num_distrito_militar,
        codigo_icfes: data.graduated?.codigo_icfes,
        estado: data.graduated?.estado,
        fecha_nacimiento:
          data.graduated?.fecha_nacimiento !== "0000-00-00" &&
          data.graduated?.fecha_nacimiento
            ? new Date(data.graduated?.fecha_nacimiento)
            : null,
        vp_elaborado_en: data.graduated?.vp_elaborado_en || "",
        email: data.graduated?.email,
        codigo_terceros: data.graduated?.codigo_terceros,
        salesforce_id: data.graduated?.salesforce_id,
      };
      setForm(newForm);
      setTitles(data.graduated?.titulos || []);
    } catch (error) {
      history.push("/graduated");
      window.location.reload();
    }
  };

  const getTitles = async () => {
    try {
      const { data } = await axios.post(
        `/title/getTitles`,
        {},
        {
          headers: { "access-token": token },
        }
      );
      setAvailableTitles(data.titles);
    } catch (error) {
      history.push("/graduated");
      window.location.reload();
    }
  };

  const getPrograms = async () => {
    try {
      const { data } = await axios.post(
        `/program/getPrograms`,
        {},
        {
          headers: { "access-token": token },
        }
      );
      setPrograms(data.programs);
    } catch (error) {
      history.push("/graduated");
      window.location.reload();
    }
  };

  const parseVp = (array, item) => {
    let _value = null;
    array.forEach((e) => {
      if (`${e.id}` === `${item}`) _value = e.valor_parametro;
    });
    return _value;
  };

  const getCities = async () => {
    const { data } = await axios.post(
      `/city/getCities`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setCiudades(data.citys);
  };

  const getParameters = async () => {
    const { data } = await axios.post(
      `/parameter/getParameters`,
      { id: [2, 4, 3, 5] },
      {
        headers: { "access-token": token },
      }
    );
    setNivelEducativoVp(
      data?.parameters[0]?.valoresParametros?.sort((a, b) =>
        a.orden < b.orden ? -1 : 1
      )
    );
    setSexoVp(
      data?.parameters[1]?.valoresParametros?.sort((a, b) =>
        a.orden < b.orden ? -1 : 1
      )
    );
    setElaboradoEnVp(
      data?.parameters[2]?.valoresParametros?.sort((a, b) =>
        a.orden < b.orden ? -1 : 1
      )
    );
    setTipoIdentificacionVp(
      data?.parameters[3]?.valoresParametros?.sort((a, b) =>
        a.orden < b.orden ? -1 : 1
      )
    );
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value.toString(),
    });
  };

  const handleCancel = () => {
    history.push("/graduated");
  };

  const updateTitles = (titles) => {
    setTitles(titles);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!props.match.params.id) {
      axios
        .post(
          `/graduated/`,
          { ...form, titles },
          {
            headers: { "access-token": token },
          }
        )
        .then((res) => {
          setLoading(false);
          if (res.data.graduatedId.success) {
            history.push("/graduated");
            Swal.fire({
              icon: "success",
              text: "Creado exitosamente.",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: res.data.graduatedId.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "No se ha podido crear.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    } else {
      const id = decrypt(props.match.params.id);
      axios
        .put(
          `/graduated/${id}`,
          { ...form, titles },
          {
            headers: { "access-token": token },
          }
        )
        .then((res) => {
          setLoading(false);
          if (res.data.graduatedId) {
            history.push("/graduated");
            Swal.fire({
              icon: "success",
              text: "Editado exitosamente.",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: res.data.updated.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "No se ha podido editar.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    }
  };

  const parseCity = (city) => {
    const ciudad = ciudades.filter(
      (e) => e.nombre.toLowerCase() === city.toLowerCase()
    );
    return ciudad[0];
  };

  const handleDateChange = (e, date) => {
    let _new = { ...form };
    if (e?._d === undefined) {
      _new[date] = null;
    } else {
      _new[date] = e?._d;
    }

    setForm(_new);
  };

  const searchForId = async () => {
    setLoading(true);
    if (form.identificacion.trim()) {
      const { data } = await axios.post(
        `/external/getGraduatedInfo`,
        { identificacion: form.identificacion },
        {
          headers: { "access-token": token },
        }
      );
      //parseVpValue
      if (data.external.graduatedData) {
        let graduated = data.external.graduatedData[0];
        let sexos = { M: 225, F: 226 };
        let ciudad = graduated.ciudad_expedicion_id
          ? parseCity(graduated.ciudad_expedicion_id)
          : null;
        let sexo = sexos[graduated.sexo];
        let tipos_id = {
          "CEDULA DE CIUDADANIA": 221,
          "CEDULA DE EXTRANJERIA": 222,
          "TARJETA DE IDENTIDAD": 233,
          PASAPORTE: 234,
        };
        let tipo_id = tipos_id[graduated.tipo_identificacion];
        let obj = {
          primer_nombre: graduated.primer_nombre || "",
          segundo_nombre: graduated.segundo_nombre || "",
          primer_apellido: graduated.primer_apellido || "",
          segundo_apellido: graduated.segundo_apellido || "",
          vp_sexo: sexo || "",
          id_ciudad_expedicion: ciudad?.id || null,
          codigo_terceros: graduated.id_tercero || "",
          identificacion: form.identificacion,
          codigo_icfes: graduated.codigo_icfes || "",
          fecha_nacimiento: graduated.fecha_nacimiento
            ? new Date(`${graduated.fecha_nacimiento} `)
            : null,
          vp_tipo_identificacion: tipo_id || "",
          num_distrito_militar: graduated.num_distrito_militar || "",
          num_libreta_militar: graduated.num_libreta_militar || "",
          email: graduated.correo_institucional || "",
          correo_personal: graduated.correo_personal || "",
          vp_elaborado_en: form.vp_elaborado_en,
          estado: form.estado,
          salesforce_id: form.salesforce_id,
        };
        setForm(obj);
      } else {
        Swal.fire({
          text: "No se encontró información de ningún graduado con esa identificación",
          icon: "error",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "No se ha digitado una identificación",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(titles);
  }, [titles]);

  const deleteTitle = (index) => {
    setTitles(titles.filter((e, _index) => _index !== index));
  };
  return (
    <Paper elevation={0}>
      <Header search={false} backRoute={"/graduated"} backButton={true} />
      <Divider />
      <div className={classes.paper}>
        <div className={classes.container}>
          <Typography component="h1" variant="h5">
            {props?.match?.params?.id ? "Editar" : "Crear"} egresado
          </Typography>
          <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TextField
                  fullWidth
                  label="Identificación"
                  name="identificacion"
                  value={form.identificacion}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={12} align="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={searchForId}
                >
                  Buscar identificación
                </Button>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl required fullWidth variant="outlined">
                  <InputLabel id="idtypeLabel">
                    Tipo de identificación
                  </InputLabel>
                  <Select
                    labelId="idtypeLabel"
                    label="Tipo de identificación"
                    value={form.vp_tipo_identificacion}
                    onChange={handleInput}
                    name="vp_tipo_identificacion"
                    className={classes.container__input_root}
                    align="left"
                  >
                    <MenuItem value="" disabled>
                      <em>Seleccione una opción</em>
                    </MenuItem>
                    {tipoIdentificacionVp.map((data) => {
                      return (
                        <MenuItem key={data.id} value={data.id}>
                          {`${data.valor_parametro}`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Primer nombre"
                  name="primer_nombre"
                  value={form.primer_nombre}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Segundo nombre"
                  name="segundo_nombre"
                  value={form.segundo_nombre}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Primer apellido"
                  name="primer_apellido"
                  value={form.primer_apellido}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Segundo apellido"
                  name="segundo_apellido"
                  value={form.segundo_apellido}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="cityLabel">Ciudad expedición</InputLabel>
                  <Select
                    labelId="cityLabel"
                    label="Ciudad expedición"
                    value={form.id_ciudad_expedicion}
                    onChange={handleInput}
                    name="id_ciudad_expedicion"
                    className={classes.container__input_root}
                    align="left"
                  >
                    <MenuItem value="" disabled>
                      <em>Seleccione una opción</em>
                    </MenuItem>
                    {ciudades
                      .sort((a, b) => (`${a.nombre}` < `${b.nombre}` ? -1 : 1))
                      .map((data) => {
                        return (
                          <MenuItem key={`city${data.id}`} value={data.id}>
                            {`${data.nombre}`}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Código icfes"
                  name="codigo_icfes"
                  value={form.codigo_icfes}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Número libreta militar"
                  name="num_libreta_militar"
                  value={form.num_libreta_militar}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Número distrito militar"
                  name="num_distrito_militar"
                  value={form.num_distrito_militar}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Correo institucional"
                  name="email"
                  value={form.email}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Correo personal"
                  name="email"
                  value={form.correo_personal}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <DatePicker
                  fullWidth
                  clearable
                  inputVariant="outlined"
                  label="Fecha de nacimiento"
                  name="fecha_nacimiento"
                  format="DD/MM/yyyy"
                  value={form.fecha_nacimiento}
                  onChange={(e) => {
                    handleDateChange(e, "fecha_nacimiento");
                  }}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="sexLabel">Sexo</InputLabel>
                  <Select
                    labelId="sexLabel"
                    label="Sexo"
                    value={form.vp_sexo}
                    onChange={handleInput}
                    name="vp_sexo"
                    className={classes.container__input_root}
                    align="left"
                  >
                    <MenuItem value="" disabled>
                      <em>Seleccione una opción</em>
                    </MenuItem>
                    {sexoVp.map((data) => {
                      return (
                        <MenuItem key={`sex${data.id}`} value={data.id}>
                          {`${data.valor_parametro}`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="elaboratedLabel">Elaborado en</InputLabel>
                  <Select
                    labelId="elaboratedLabel"
                    label="Elaborado en"
                    value={form.vp_elaborado_en}
                    onChange={handleInput}
                    name="vp_elaborado_en"
                    className={classes.container__input_root}
                    align="left"
                  >
                    <MenuItem value="" disabled>
                      <em>Seleccione una opción</em>
                    </MenuItem>
                    {elaboradoEnVp.map((data) => {
                      return (
                        <MenuItem key={`elaborated${data.id}`} value={data.id}>
                          {`${data.valor_parametro}`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sx={12} style={{ maxWidth: "100%" }}>
                <TitleModalForm
                  updateTitles={updateTitles}
                  titulos={availableTitles}
                  parseVp={parseVp}
                  titles={titles}
                  programs={programs}
                  setTitles={setTitles}
                />
                <Table columns={columns} rows={titles} pagination={false}>
                  <TableBody>
                    {titles?.length > 0 ? (
                      <>
                        {titles.map((row, index) => (
                          <TableRow key={`title${index}`}>
                            <TableCell align="center">
                              {permission.includes(3) && (
                                <TitleModalForm
                                  editData={row}
                                  titulos={availableTitles}
                                  parseVp={parseVp}
                                  updateTitles={updateTitles}
                                  titles={titles}
                                  idx={index}
                                  setTitles={setTitles}
                                  programs={programs}
                                />
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {permission.includes(4) && (
                                <Tooltip title="Eliminar">
                                  <IconButton
                                    aria-label="edit"
                                    onClick={(e) => deleteTitle(index)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {row?.Titulo?.nombre}
                            </TableCell>
                            <TableCell align="center">
                              {parseVp(
                                nivelEducativoVp,
                                row?.Titulo?.vp_nivel_educativo
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {new Date(
                                `${row?.fecha_grado} `
                              ).toLocaleDateString("en-GB")}
                            </TableCell>
                            <TableCell align="center">
                              {row?.numero_acta}
                            </TableCell>
                            <TableCell align="center">{row?.folio}</TableCell>
                            <TableCell align="center">{row?.libro}</TableCell>
                            <TableCell align="center">
                              {row?.numero_registro}
                            </TableCell>
                            <TableCell align="center">
                              {row?.ubicacion_az}
                            </TableCell>
                            <TableCell align="center">
                              {row?.proyecto_grado}
                            </TableCell>
                            <TableCell align="center">
                              {row?.numero_folio}
                            </TableCell>
                            <TableCell align="center">
                              {new Date(`${row?.createdAt}`).toLocaleDateString(
                                "en-GB"
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {new Date(`${row?.updatedAt}`).toLocaleDateString(
                                "en-GB"
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {row?.estado === 1 ? "Activo" : "Inactivo"}
                            </TableCell>
                            <TableCell align="center">
                              {row?.codigo_snies}
                            </TableCell>
                            <TableCell align="center">
                              {row?.procodigo_snies}
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan="15">
                          No hay datos registrados
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <div className={classes.containerButton}>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                type="submit"
              >
                Guardar
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={handleCancel}
              >
                Cancelar
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Backdrop loading={loading} />
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1em",
  },
  paper: {
    margin: theme.spacing(2, 2),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    width: "80%",
  },
  containerButton: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginTop: "1em",
      marginBottom: "1em",
    },
  },
  button: {
    margin: "0.5em",
    padding: ".5em 3em",
    borderRadius: "10px",
  },
  container__input_root: {
    borderRadius: "10px",
  },
}));

const columns = [
  {
    id: "action",
    label: "",
    minWidth: 100,
    align: "center",
    colSpan: 2
  },
  {
    id: "title",
    label: "Título",
    minWidth: 100,
    align: "center",
  },
  {
    id: "educationLevel",
    label: "Nivel educativo",
    minWidth: 100,
    align: "center",
  },
  {
    id: "graduationDate",
    label: "Fecha de Graduación",
    minWidth: 150,
    align: "center",
  },
  {
    id: "minuteNumber",
    label: "Número de acta",
    minWidth: 150,
    align: "center",
  },
  {
    id: "sheet",
    label: "Folio",
    minWidth: 100,
    align: "center",
  },
  {
    id: "book",
    label: "Libro",
    minWidth: 100,
    align: "center",
  },
  {
    id: "recordNumber",
    label: "Número de registro",
    minWidth: 100,
    align: "center",
  },
  {
    id: "azLocation",
    label: "Ubicación AZ",
    minWidth: 100,
    align: "center",
  },
  {
    id: "project",
    label: "Proyecto de grado",
    minWidth: 100,
    align: "center",
  },
  {
    id: "sheetNumber",
    label: "Número folio",
    minWidth: 100,
    align: "center",
  },
  {
    id: "creationDate",
    label: "Fecha de creación",
    minWidth: 100,
    align: "center",
  },
  {
    id: "updateDate",
    label: "Fecha actualización",
    minWidth: 100,
    align: "center",
  },
  {
    id: "status",
    label: "Estado",
    minWidth: 100,
    align: "center",
  },
  {
    id: "snies",
    label: "Código SNIES",
    minWidth: 100,
    align: "center",
  },
  {
    id: "procodigos_snies",
    label: "Procódigo SNIES",
    minWidth: 100,
    align: "center",
  },
];

const mapStateToProps = (state) => {
  return {
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 8)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToProps = {
  setBreadcrumps,
};

export default connect(mapStateToProps, mapDispatchToProps)(GraduatedForm);
