import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Tooltip,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from "@material-ui/core";
import { setBreadcrumps, setPage } from "../../actions";
import Table from "../../components/Table";
import Header from "../../components/Header";
import axios from "../../api";
import Backdrop from "../../components/Backdrop";
import Swal from "sweetalert2";
import "./logs.css";

function LogsList(props) {
  const { page, rowsPerPage, setBreadcrumps, permission, token, setPage } =
    props;
  const history = useHistory();
  const [integraciones, setIntegraciones] = useState([]);
  const [filtro, setFiltro] = useState([]);
  const [total, setTotal] = useState(0);
  const [fakeIntegration, setFakeIntegration] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (permission.includes(1)) {
      getIntegrations();
      setBreadcrumps([
        { name: "Integraciones" },
        { name: "Gestionar integraciones" },
      ]);
    } else {
      history.push("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIntegrations = async () => {
    setLoading(true);
    const { data } = await axios.post(
      `/integration/getIntegrations`,
      { estado: [-1, 1, 0] },
      {
        headers: { "access-token": token },
      }
    );
    setIntegraciones(data.integrations);
    setLoading(false);
  };

  useEffect(() => {
    if (`${fakeIntegration}`.trim()) {
      getLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getLogs = async () => {
    if (`${fakeIntegration}`.trim()) {
      setLoading(true);
      const { data } = await axios.post(
        `/log/getLogs`,
        {
          rowsPerPage,
          page,
          where: { id_integraciones: fakeIntegration },
        },
        {
          headers: { "access-token": token },
        }
      );
      setFiltro(data.logs?.logs);
      setTotal(data.logs?.count);
      setLoading(false);
    } else {
      Swal.fire({
        icon: "error",
        text: "Debe seleccionar una integración para poder filtrar",
      });
    }
  };

  return (
    <Paper elevation={0} className="pw-2">
      <Header search={false} />
      <Grid container spacing={2}>
        <Grid item style={{ textAlign: "center" }} xs={12}>
          <FormControl variant="outlined">
            <InputLabel id="integrationLabel">Integración</InputLabel>
            <Select
              label="Integración"
              labelId="integrationLabel"
              value={fakeIntegration}
              onChange={(e) => setFakeIntegration(e.target.value)}
              name="fakeIntegration"
              style={{ minWidth: "10em" }}
            >
              <MenuItem value="">
                <em>Seleccione una opción</em>
              </MenuItem>
              {integraciones.map((data) => {
                return (
                  <MenuItem key={`parameter-${data.id}`} value={data.id}>
                    {`${data.nombre}`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <br />
          <br />
          <Button
            align="center"
            variant="contained"
            color="primary"
            onClick={() => {
              setPage(0);
              getLogs();
            }}
          >
            Mostrar logs
          </Button>
          <br />
          <br />
        </Grid>
      </Grid>

      <Table columns={columns} rows={filtro} count={total}>
        <TableBody>
          {filtro?.length > 0 ? (
            <>
              {filtro.map((row, index) => (
                <TableRow key={`row${row.id}`}>
                  <TableCell align="center">
                    {permission.includes(3) && (
                      <>
                        <Tooltip title="Descargar">
                          <a
                            href={URL.createObjectURL(
                              new Blob([row.res], {
                                type: "application/json",
                              })
                            )}
                            className={"downloadLink"}
                            download={`log_${row.fecha_hora}.json`}
                            rel="norefeerer"
                          >
                            <Button variant="contained" color="primary">
                              Descargar
                            </Button>
                          </a>
                        </Tooltip>
                      </>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {new Date(row?.fecha_hora).toLocaleString("en-GB")}
                  </TableCell>
                  <TableCell align="center">{row?.log}</TableCell>
                  <TableCell align="center">
                    {row?.error === 1 ? "Sí" : "No"}
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="15">
                No hay datos registrados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Backdrop loading={loading} />
    </Paper>
  );
}

const columns = [
  {
    id: "acciones",
    label: "Acciones",
    align: "center",
    minWidth: 10,
  },
  {
    id: "name",
    label: "Fecha",
    align: "center",
    minWidth: 10,
  },
  {
    id: "message",
    label: "Mensaje",
    align: "center",
    minWidth: 10,
  },
  {
    id: "error",
    label: "¿Es error?",
    align: "center",
    minWidth: 10,
  },
];

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 13)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToProps = {
  setBreadcrumps,
  setPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsList);
