import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import "./graduated.css";
import Logo from "../../assets/img/Membrete_Header.png";
import Footer from "../../assets/img/Membrete_Footer.png";
import Duplicado from "../../duplicado2.png";
import { useHistory } from "react-router-dom";
import { decrypt } from "../../utils/crypt";
import { connect } from "react-redux";
import axios from "../../api";

function Minute(props) {
  const { token } = props;
  const history = useHistory();
  const [graduated, setGraduated] = useState({});
  const [tipoIdentificacionVp, setTipoIdentificacionVp] = useState([]);

  const getParameters = async () => {
    const { data } = await axios.post(
      `/parameter/getParameters`,
      { id: [2] },
      {
        headers: { "access-token": token },
      }
    );
    setTipoIdentificacionVp(
      data?.parameters[0]?.valoresParametros?.sort((a, b) =>
        a.orden < b.orden ? -1 : 1
      )
    );
  };

  const fetch = async () => {
    let { data } = await axios.post(
      `/graduated/title/${decrypt(props.match.params.id)}`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setGraduated(data?.graduatedTitle);
    /*window.onafterprint = () => {
      window.close();
    };
    window.print();*/
  };

  useEffect(() => {
    if (props.match.params.id) {
      getParameters();
      fetch();
    } else {
      history.push("/");
    }
    //eslint-disable-next-line
  }, []);

  const parseVp = (array, item) => {
    let _value = null;
    array.forEach((e) => {
      if (`${e.id}` === `${item}`) _value = e.valor_parametro;
    });
    return _value;
  };

  const replaceLegendText = (text) => {
    return text
      .replace("á", "a")
      .replace("é", "e")
      .replace("í", "i")
      .replace("ó", "o")
      .replace("ú", "u")
      .replace("Á", "A")
      .replace("É", "E")
      .replace("Í", "I")
      .replace("Ó", "O")
      .replace("Ú", "U");
  };
  const meses = {
    0: "Enero",
    1: "Febrero",
    2: "Marzo",
    3: "Abril",
    4: "Mayo",
    5: "Junio",
    6: "Julio",
    7: "Agosto",
    8: "Septiembre",
    9: "Octubre",
    10: "Noviembre",
    11: "Diciembre",
  };

  return (
    <Paper elevation={0}>
      <div
        style={{ width: "auto", backgroundColor: "#ffffff", maxWidth: "100%", fontFamily: "Times New Roman" }}
      >
        <div style={{ margin: "0px 60px" }}>
          <div>
            <img
              src={Logo}
              alt="Logo CUC"
              style={{ margin: "1px", width: "100%" }}
            />
          </div>
          <div style={{ padding: "0px 20px" }}>
            <img src={Duplicado} alt="Logo DUPLICADO" style={{ margin: "1px" }} />
            <h3
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                color: "#0C0707",
                textAlign: "right",
              }}
            >
              ACTA DE GRADO N°{" "}
              {graduated?.numero_acta?.length > 0 &&
                `${"0".repeat(
                  3 - `${graduated?.numero_acta}`.length > 0
                    ? 3 - `${graduated?.numero_acta}`.length
                    : 0
                )}${graduated?.numero_acta}`}
            </h3>

            <p
              style={{
                marginTop: "40px",
                fontSize: "16px",
                textAlign: "justify",
              }}
            >
              En Barranquilla, Capital del Departamento de Atlántico, a{" "}
              {new Date(`${graduated?.fecha_grado} `).toLocaleDateString(
                "es-ES",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}{" "}
              se reunieron los Miembros Directivos de la{" "}
              {graduated?.leyenda?.nombre_universidad ||
                "CORPORACION UNIVERSITARIA DE LA COSTA CUC"}{" "}
              con el objeto de otorgar el título de{" "}
              <b>{graduated?.Titulo?.nombre}</b>{" "}
              {graduated?.egresados?.vp_sexo
                ? graduated?.egresados?.vp_sexo === 225
                  ? "al"
                  : "a la"
                : "al"}{" "}
              estudiante{" "}
              <b>
                {`${graduated?.egresados?.primer_nombre} ${graduated?.egresados?.segundo_nombre} ${graduated?.egresados?.primer_apellido} ${graduated?.egresados?.segundo_apellido}`
                  ?.trim()
                  .toUpperCase()}
              </b>{" "}
              portador de la{" "}
              {parseVp(
                tipoIdentificacionVp,
                graduated?.egresados?.vp_tipo_identificacion
              )}{" "}
              No.{" "}
              {parseInt(graduated?.egresados?.identificacion)?.toLocaleString()} a
              quien se le tomó el juramento de rigor y se le hizo entrega del
              Diploma.
            </p>
            <p
              style={{
                marginTop: "20px",
                fontSize: "16px",
                textAlign: "justify",
              }}
            >
              Habiendo aprobado los correspondientes estudios, según consta en los
              respectivos registros del programa de{" "}
              {graduated?.Titulo?.Programa?.nombre}{" "}
              {graduated?.proyecto_grado?.trim() ? (
                <>
                  y habiendo cumplido los requisitos académicos del grado:
                  <br />
                  <br />
                  <b>{graduated?.proyecto_grado}</b>
                </>
              ) : (
                <>.</>
              )}
              <br />
              <br />
              {
                /*graduated?.procodigo_snies?.trim() && (
                <>
                  <br />
                  <br />
                  Por registro No. {graduated?.procodigo_snies} emanada del
                  MINISTERIO DE EDUCACION NACIONAL, despues de haber cumplido con
                  los requisitos exigidos por la CORPORACION UNIVERSITARIA DE LA
                  COSTA-CUC, se otorga el titulo correspondiente.
                </>
              )*/
                graduated?.leyenda?.TEXTO
                  ? replaceLegendText(graduated?.leyenda.TEXTO)
                  : "LA FECHA DE GRADUACION NO CORRESPONDE A NINGUNA LEYENDA EN NUESTROS REGISTROS"
              }
            </p>
            <p
              style={{
                marginTop: "40px",
                fontSize: "16px",
                textAlign: "justify",
              }}
            ></p>
            <p
              style={{
                marginTop: "40px",
                fontSize: "16px",
                textAlign: "justify",
              }}
            >
              Para constancia de lo expuesto, se firma la presente acta por el
              Presidente del Consejo, Directivo, Rector, Decano de la Facultad y
              Secretario General.
            </p>
            <p
              style={{
                marginTop: "40px",
                fontSize: "16px",
                textAlign: "justify",
              }}
            >
              Este documento es fiel copia de su original, dado en Barranquilla a
              los ({new Date().getDate()}) días del mes de{" "}
              {meses[new Date().getMonth()]} del año {new Date().getFullYear()}.
            </p>
            <br />
            <b>
              <p
                style={{
                  marginTop: "40px",
                  fontSize: "16px",
                  textAlign: "justify",
                }}
              >
                FEDERICO BORNACELLI VARGAS
              </p>
            </b>
            <p
              style={{
                marginTop: "40px",
                fontSize: "16px",
                textAlign: "justify",
              }}
            >
              Secretario General
            </p>

          </div>
          <div style={{marginTop: "100px"}}>
            <img
              src={Footer}
              alt="Logo CUC"
              style={{ margin: "1px", width: "100%" }}
            />
          </div>
        </div>
      </div>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(Minute);
