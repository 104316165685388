import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Tabs,
  Tab,
} from "@material-ui/core";
import { setBreadcrumps } from "../../actions";
import Table from "../../components/Table";
import Header from "../../components/Header";
import axios from "../../api";
import Backdrop from "../../components/Backdrop";
import Swal from "sweetalert2";
import TabPanel from "../../components/TabPane";

function Reports(props) {
  const { setBreadcrumps, permission, token } = props;
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const [filtro, setFiltro] = useState([]);
  const [dataExcel, setDataExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [program, setProgram] = useState("");
  const [programs, setPrograms] = useState([]);

  const dataToExcel = async () => {
    // eslint-disable-next-line array-callback-return
    setLoading(true);
    const arrayExcel = [];
    await Promise.all(
      filtro.map((item) => {
        return arrayExcel.push({
          Año: item.year || "",
          Graduados: item.count || "",
        });
      })
    );
    setLoading(false);
    setDataExcel(arrayExcel);
  };

  useEffect(() => {
    if (permission.includes(1)) {
      setBreadcrumps([]);
      getPrograms();
    } else {
      history.push("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPrograms = async () => {
    const { data } = await axios.post(
      `/program/getPrograms`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setPrograms(data.programs);
  };

  const getReport = async () => {
    if (program !== "") {
      setLoading(true);
      const { data } = await axios.post(
        `/reports/program`,
        { id: program },
        {
          headers: { "access-token": token },
        }
      );
      setFiltro(data?.report);
      dataToExcel();
      setLoading(false);
    } else {
      Swal.fire({
        icon: "error",
        text: "Debe seleccionar un programa para poder realizar el reporte",
      });
    }
  };

  return (
    <Paper elevation={0} className="pw-2">
      <Tabs
        value={tab}
        onChange={(e, value) => setTab(value)}
        aria-label="basic tabs example"
      >
        <Tab label="Programas" />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <Header
          tableName={"report"}
          exportButton={true}
          dataToExcel={{ csvData: dataExcel, fileName: "Reporte programa" }}
          items={filtro}
          setItems={setFiltro}
        />
        <Typography align="center">
          Programa <br />
          <br />
          <FormControl style={{ marginLeft: ".5em" }} variant="outlined">
            <Select
              autoWidth={true}
              value={program}
              onChange={(e) => setProgram(e.target.value)}
              name="program"
              style={{ minWidth: "10em" }}
            >
              <MenuItem value="">
                <em>Seleccione una opción</em>
              </MenuItem>
              {programs
                .sort((a, b) => (`${a.nombre}` < `${b.nombre}` ? -1 : 1))
                .map((data) => {
                  return (
                    <MenuItem key={`parameter-${data.id}`} value={data.id}>
                      {`${data.nombre}`}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <br />
          <br />
          <Button onClick={getReport} variant="outlined" color="primary">
            Ejecutar reporte
          </Button>
        </Typography>

        <Table columns={columns} rows={filtro} pagination={false}>
          <TableBody>
            {filtro?.length > 0 ? (
              <>
                {filtro.map((row, index) => (
                  <TableRow key={`row${row.id}`}>
                    <TableCell align="center">{row?.year}</TableCell>
                    <TableCell align="center">{row?.count}</TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell align="center" colSpan="15">
                  No hay datos registrados
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Backdrop loading={loading} />
      </TabPanel>
    </Paper>
  );
}

const columns = [
  {
    id: "year",
    label: "Año",
    minWidth: 100,
    align: "center",
  },
  {
    id: "count",
    label: "Graduados",
    minWidth: 100,
    align: "center",
  },
];

const mapStateToProps = (state) => {
  return {
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 10)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToProps = {
  setBreadcrumps,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
