import React from "react";
import { connect } from "react-redux";
import {
  makeStyles,
  withStyles,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TablePagination,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { setPage, setRowsPerPage } from "../actions";
import TablePaginationActions from "./TablePaginationActions";

const StyledTableCell = withStyles(() => ({
  head: {
    fontWeight: "bold",
    fontSize: 16,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: "auto",
  },
  adjustWidth: {
    maxWidth: "100%",
  },
});

const BasicTable = (props) => {
  const { rows, columns, children, page, rowsPerPage, small = true, setPage, setRowsPerPage, count } = props;
  const classes = useStyles();

  const handleSetPage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSetRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.adjustWidth}>
      <TableContainer component={Paper} style={{ maxHeight: "30rem" }}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="simple table"
          size={small && "small"}
        >
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, width: column.width }}
                  colSpan={column.colSpan}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          {children}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={count ? count : rows ? rows?.length : 1}
        rowsPerPage={rowsPerPage}
        page={page}
        
        labelRowsPerPage="Filas por página"
        onPageChange={handleSetPage}
       
        onRowsPerPageChange={handleSetRowsPerPage}
        ActionsComponent={(props) => {
          return <TablePaginationActions {...props}/>
        }}
      />
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
  };
};

const mapDispatchToProps = {
  setPage,
  setRowsPerPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicTable);
