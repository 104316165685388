import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import Swal from "sweetalert2";

const TitleModalForm = ({
  editData = null,
  setTitles,
  titulos,
  idx=null,
  vpNivelEducativo,
}) => {
  const [open, setOpen] = useState(false);


  const [form, setForm] = useState({
    id: editData?.id,
    nombre: editData?.nombre || "",
    vp_nivel_educativo: editData?.vp_nivel_educativo || "",
    estado: editData?.estado || "",
  });

  const handleAction = () => {
    if (
      `${form.nombre}`.trim() !== "" &&
      `${form.vp_nivel_educativo}`.trim() !== ""
    ) {
      if ((form.id !== null && form.id !== undefined) || (idx !== null && idx !== undefined)) {
        let add = { ...form };
        let titulosActual = [...titulos, ];
        titulosActual[idx] = add;
        setTitles([...titulosActual]);
        Swal.fire({
          text: "Actualizado correctamente",
          icon: "success",
        });
        setOpen(false);
      } else {
        let add = { ...form };
        let newTitles = [...titulos, add];
        setTitles(newTitles);
        Swal.fire({
          text: "Se añadió correctamente",
          icon: "success",
        });
        setOpen(false);
      }
    } else {
      Swal.fire({
        text: "Debe llenar todos los campos para poder crear o editar un título",
        icon: "error",
      });
    }
  };
  const clear = () => {
    setForm({
      id: editData?.id,
      nombre: editData?.nombre || "",
      vp_nivel_educativo: editData?.vp_nivel_educativo || "",
      estado: editData?.estado || "",
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "80%",
    background: "white",
    boxShadow: 24,
    padding: "2rem",
    p: 4,
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const classes = useStyles();

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value.toString(),
    });
  };

  return (
    <>
      {editData ? (
        <Tooltip title="Editar">
          <IconButton
            aria-label="edit"
            onClick={(e) => {
              clear();
              setOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faEdit} size={"xs"} />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={(e) => {
            clear();
            setOpen(true);
          }}
        >
          Añadir título
        </Button>
      )}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style} align="center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {editData ? "Editar" : "Crear"} título
          </Typography>
          <br />
          <form>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nombre"
                  name="nombre"
                  value={form.nombre}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl required fullWidth variant="outlined">
                  <InputLabel id="educativeLabel">Nivel educativo</InputLabel>
                  <Select
                    labelId="educativeLabel"
                    label="Nivel educativo"
                    value={form.vp_nivel_educativo}
                    onChange={handleInput}
                    name="vp_nivel_educativo"
                    className={classes.container__input_root}
                    align="left"
                  >
                    <MenuItem value="" disabled>
                      <em>Seleccione una opción</em>
                    </MenuItem>
                    {vpNivelEducativo?.map((e) => (
                      <MenuItem value={e.id}>{e.valor_parametro}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl required fullWidth variant="outlined">
                  <InputLabel id="statusLabel">Estado</InputLabel>
                  <Select
                    labelId="statusLabel"
                    label="Estado"
                    value={form.estado}
                    onChange={handleInput}
                    name="estado"
                    className={classes.container__input_root}
                    align="left"
                  >
                    <MenuItem value="" disabled>
                      <em>Seleccione una opción</em>
                    </MenuItem>
                    <MenuItem value={0}>Inactivo</MenuItem>
                    <MenuItem value={1}>Activo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
          <div className={classes.containerButton}>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              type="submit"
              onClick={handleAction}
            >
              Guardar
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={handleCancel}
            >
              Cancelar
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1em",
  },
  paper: {
    margin: theme.spacing(2, 2),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    width: "80%",
  },
  containerButton: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginTop: "1em",
      marginBottom: "1em",
    },
  },
  button: {
    margin: "0.5em",
    padding: ".5em 3em",
    borderRadius: "10px",
  },
  container__input_root: {
    borderRadius: "10px",
  },
}));

export default TitleModalForm;
