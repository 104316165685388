import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setBreadcrumps } from "../../actions";
import {
  makeStyles,
  TextField,
  Button,
  Grid,
  Paper,
  Divider,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { decrypt } from "../../utils/crypt";
import Header from "../../components/Header";
import Swal from "sweetalert2";
import Backdrop from "../../components/Backdrop";
import axios from "../../api";
import Table from "../../components/Table";
import TitleModalForm from "../../components/TitleCreationModalForm";


function ProgramForm(props) {
  const { setBreadcrumps, permission, token } = props;
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [titles, setTitles] = useState([]);
  const [form, setForm] = useState({
    nombre: "",
    codigo_snies:"",
    procodigo_snies:"",
    estado:""
  });
  const [nivelEducativoVp, setNivelEducativoVp] = useState([]);

  useEffect(() => {
    getParameters();
    if (permission.includes(2) || permission.includes(3)) {
      setBreadcrumps([
        { name: "Programas", route: "/programs" },
        { name: props?.match?.params?.id ? "Editar" : "Crear" },
      ]);
      props?.match?.params?.id && getProgram();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProgram = async () => {
    try {
      const id = decrypt(props.match.params.id);
      const { data } = await axios.get(`/program/${id}`, {
        headers: { "access-token": token },
      });
      let newForm = {
       nombre: data.program?.nombre,
       codigo_snies: data.program?.codigo_snies,
       procodigo_snies: data.program?.procodigo_snies,
       estado: data.program?.estado
      };
      setForm(newForm);
      setTitles(data.program?.titulos);
    } catch (error) {
      history.push("/programs");
      window.location.reload();
    }
  };

  const parseVp = (array, item) => {
    let _value = null;
    array.forEach((e) => {
      if (`${e.id}` === `${item}`) _value = e.valor_parametro;
    });
    return _value;
  };


  const getParameters = async () => {
    const { data } = await axios.post(
      `/parameter/getParameters`,
      { id: [5] },
      {
        headers: { "access-token": token },
      }
    );
    setNivelEducativoVp(
      data?.parameters[0]?.valoresParametros?.sort((a, b) =>
        a.orden < b.orden ? -1 : 1
      )
    );
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value.toString(),
    });
  };

  const handleCancel = () => {
    history.push("/programs");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!props.match.params.id) {
      axios
        .post(
          `/program/`,
          { ...form, titles },
          {
            headers: { "access-token": token },
          }
        )
        .then((res) => {
          setLoading(false);
          if (res.data.programId) {
            history.push("/programs");
            Swal.fire({
              icon: "success",
              text: "Creado exitosamente.",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: res.data.created.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "No se ha podido crear.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    } else {
      const id = decrypt(props.match.params.id);
      axios
        .put(
          `/program/${id}`,
          { ...form, titles },
          {
            headers: { "access-token": token },
          }
        )
        .then((res) => {
          setLoading(false);
          if (res.data.programId) {
            history.push("/programs");
            Swal.fire({
              icon: "success",
              text: "Editado exitosamente.",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: res.data.updated.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "No se ha podido editar.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    }
  };

  return (
    <Paper elevation={0}>
      <Header search={false} backRoute={"/graduated"} backButton={true} />
      <Divider />
      <div className={classes.paper}>
        <div className={classes.container}>
          <Typography component="h1" variant="h5">
            {props?.match?.params?.id ? "Editar" : "Crear"} programa
          </Typography>
          <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TextField
                  fullWidth
                  label="Nombre"
                  name="nombre"
                  value={form.nombre}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  fullWidth
                  label="Código SNIES"
                  name="codigo_snies"
                  value={form.codigo_snies}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  fullWidth
                  label="Procódigo SNIES"
                  name="procodigo_snies"
                  value={form.procodigo_snies}
                  variant="outlined"
                  onChange={handleInput}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl required fullWidth variant="outlined">
                  <InputLabel id="statusLabel">Estado</InputLabel>
                  <Select
                    labelId="statusLabel"
                    label="Estado"
                    value={form.estado}
                    onChange={handleInput}
                    name="estado"
                    className={classes.container__input_root}
                    align="left"
                  >
                    <MenuItem value="" disabled>
                      <em>Seleccione una opción</em>
                    </MenuItem>
                    <MenuItem value="0">Inactivo</MenuItem>
                    <MenuItem value="1">Activo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sx={12} style={{ width: "100%" }}>
                <TitleModalForm
                  titulos={titles}
                  parseVp={parseVp}
                  titles={titles}
                  setTitles={setTitles}
                  vpNivelEducativo={nivelEducativoVp}
                />
                <Table columns={columns} rows={titles} pagination={false}>
                  <TableBody>
                    {titles?.length > 0 ? (
                      <>
                        {titles.map((row, index) => (
                          <TableRow key={`title${index}`}>
                            <TableCell align="center">
                              {permission.includes(3) && (
                                <TitleModalForm
                                  editData={row}
                                  titulos={titles}
                                  parseVp={parseVp}
                                  titles={titles}
                                  idx={index}
                                  vpNivelEducativo={nivelEducativoVp}
                                  setTitles={setTitles}
                                />
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {row?.nombre}
                            </TableCell>
                            <TableCell align="center">
                              {parseVp(
                                nivelEducativoVp,
                                row?.vp_nivel_educativo
                              )}
                            </TableCell>
                            <TableCell align="center">
                               {`${row?.estado}` === "1" ? "Activo":"Inactivo"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <TableCell align="center" colSpan="15">
                        No hay datos registrados
                      </TableCell>
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <div className={classes.containerButton}>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                type="submit"
              >
                Guardar
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={handleCancel}
              >
                Cancelar
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Backdrop loading={loading} />
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1em",
  },
  paper: {
    margin: theme.spacing(2, 2),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    width: "80%",
  },
  containerButton: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginTop: "1em",
      marginBottom: "1em",
    },
  },
  button: {
    margin: "0.5em",
    padding: ".5em 3em",
    borderRadius: "10px",
  },
  container__input_root: {
    borderRadius: "10px",
  },
}));

const columns = [
  {
    id: "action",
    label: "",
    minWidth: 100,
    align: "center",
  },
  {
    id: "nombre",
    label: "Nombre",
    minWidth: 100,
    align: "center",
  },
  {
    id: "educativeLevel",
    label: "Nivel educativo",
    minWidth: 100,
    align: "center",
  },
  {
    id: "status",
    label: "Estado",
    minWidth: 100,
    align: "center",
  },
];

const mapStateToProps = (state) => {
  return {
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 8)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToProps = {
  setBreadcrumps,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramForm);
