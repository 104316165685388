import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import React, { useState } from "react";
import ReadIcon from "@material-ui/icons/ChromeReaderMode";
import Table from "./Table";
import Swal from "sweetalert2";

const ModalTitles = ({ graduatedData, nivelEducativoVp, parseVp }) => {
  const [open, setOpen] = useState(false);


  const alerta = (texto) => {
    Swal.fire({
      text: texto,
    });
  };

  return (
    <>
      <Tooltip title="Ver títulos">
        <IconButton aria-label="read" onClick={(e) => setOpen(true)}>
          <ReadIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        style={{ overflow: "scroll" }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent align="center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Títulos egresado
          </Typography>
          <Table
            columns={columns}
            rows={graduatedData?.titulos}
            pagination={false}
          >
            <TableBody>
              {graduatedData?.titulos?.length > 0 ? (
                <>
                  {graduatedData?.titulos.map((row, index) => (
                    <TableRow key={`title${index}`}>
                      <TableCell align="center">
                        {row?.Titulo?.nombre}
                      </TableCell>
                      <TableCell align="center">
                        {parseVp(
                          nivelEducativoVp,
                          row?.Titulo?.vp_nivel_educativo
                        )}
                      </TableCell>
                      <TableCell align="center">{row?.fecha_grado}</TableCell>
                      <TableCell align="center">{row?.numero_acta}</TableCell>
                      <TableCell align="center">{row?.folio}</TableCell>
                      <TableCell align="center">{row?.libro}</TableCell>
                      <TableCell align="center">
                        {row?.numero_registro}
                      </TableCell>
                      <TableCell align="center">{row?.ubicacion_az}</TableCell>
                      <TableCell align="center">
                        {row?.proyecto_grado?.length > 0 ? (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              alerta(row?.proyecto_grado);
                            }}
                          >
                            Mostrar proyecto
                          </Button>
                        ) : (
                          <Typography>
                            No se ha digitado un proyecto para este grado
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="center">{row?.numero_folio}</TableCell>
                      <TableCell align="center">
                        {row?.createdAt?.substring(0, 10)}
                      </TableCell>
                      <TableCell align="center">
                        {row?.updatedAt?.substring(0, 10)}
                      </TableCell>
                      <TableCell align="center">
                        {row?.codigo_snies}
                      </TableCell>
                      <TableCell align="center">
                        {row?.procodigo_snies}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableCell align="center" colSpan="15">
                  No hay datos registrados
                </TableCell>
              )}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
};

const columns = [
  {
    id: "title",
    label: "Título",
    minWidth: 100,
    align: "center",
  },
  {
    id: "educationLevel",
    label: "Nivel educativo",
    minWidth: 100,
    align: "center",
  },
  {
    id: "graduationDate",
    label: "Fecha de Graduación",
    minWidth: 150,
    align: "center",
  },
  {
    id: "minuteNumber",
    label: "Número de acta",
    minWidth: 150,
    align: "center",
  },
  {
    id: "sheet",
    label: "Folio",
    minWidth: 100,
    align: "center",
  },
  {
    id: "book",
    label: "Libro",
    minWidth: 100,
    align: "center",
  },
  {
    id: "recordNumber",
    label: "Número de registro",
    minWidth: 100,
    align: "center",
  },
  {
    id: "azLocation",
    label: "Ubicación AZ",
    minWidth: 100,
    align: "center",
  },
  {
    id: "project",
    label: "Proyecto de grado",
    minWidth: 100,
    align: "center",
  },
  {
    id: "sheetNumber",
    label: "Número folio",
    minWidth: 100,
    align: "center",
  },
  {
    id: "creationDate",
    label: "Fecha de creación",
    minWidth: 100,
    align: "center",
  },
  {
    id: "updateDate",
    label: "Fecha actualización",
    minWidth: 100,
    align: "center",
  },
  {
    id: "snies",
    label: "Código SNIES",
    minWidth: 100,
    align: "center",
  },
  {
    id: "procodigo_snies",
    label: "Procódigo SNIES",
    minWidth: 100,
    align: "center",
  },
];

export default ModalTitles;
