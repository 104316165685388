import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { loginRequest, setPermissions, setToken } from "../actions";
import {
  makeStyles,
  Grid,
  Button,
  CssBaseline,
  Paper,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "../api";
import Swal from "sweetalert2";
import HomeBackground from "../assets/img/fondo.png";
import Backdrop from "../components/Backdrop";
import Logo from "../assets/img/logologin.png";

function SignIn(props) {
  const { user, loginRequest, setPermissions, setToken } = props;
  const history = useHistory();
  const classes = useStyles();
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);

  if (Object.keys(user || {}).length > 0) {
    if (user?.id_grupos_usuarios === 1 || user?.id_grupos_usuarios === 2) {
      history.push("/users");
    } else {
      history.push("/graduated");
      window.location.reload();
    }
  }

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`/user/signin`, form)
      .then((res) => {
        if (res.data.user.success) {
          loginRequest(res.data?.user?.user);
          setPermissions(res.data?.user?.permissions);
          setToken(res.data?.user?.token);
          if (
            res.data?.user?.user?.id_grupos_usuarios === 1 ||
            res.data?.user?.user?.id_grupos_usuarios === 2
          ) {
            history.push("/users");
          } else {
            history.push("/graduated");
            window.location.reload();
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: "Usuario y/o contraseña incorrecta.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} md={7} className={classes.image}>
        {/* <Typography component="h1" variant="h4" className={classes.textField}>
          Aprende a desarrollar mejores prácticas para el Aula
        </Typography> */}
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        component={Paper}
        className={classes.login}
        elevation={6}
        square
      >
        <div className={classes.paper}>
          <div className={classes.containerLogo}>
            {/* <img alt="logo" src={Logo} /> */}
          </div>
          <img alt="logo" src={Logo} width="90%" />
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="normal"
              label="Correo electrónico"
              name="email"
              style={{ color: "white" }}
              autoComplete="email"
              type="email"
              onChange={handleInput}
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              InputProps={{
                classes: {
                  root: classes.container__input_root,
                  notchedOutline: classes.specialOutline,
                },
              }}
            />
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="normal"
              label="Contraseña"
              style={{ color: "white" }}
              name="password"
              autoComplete="current-password"
              type="password"
              onChange={handleInput}
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              InputProps={{
                classes: {
                  root: classes.container__input_root,
                  notchedOutline: classes.specialOutline,
                },
              }}
            />
            <Button
              color="primary"
              type="submit"
              variant="contained"
              className={classes.submit}
            >
              <Typography variant="body1" style={{fontWeight:"bold"}} component="h1">INICIAR SESIÓN</Typography>
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  href="#"
                  variant="body2"
                  onClick={() => history.push("/account/forget")}
                  color="primary"
                  style={{ fontWeight: "bolder", color: "#dcb940" }}
                >
                  ¿Olvidaste la contraseña?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
      <Backdrop loading={loading} />
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  specialOutline: {
    borderColor: "white !important",
    borderWidth: 2,
  },
  root: {
    overflow: "hidden",
    height: "100vh",
    backgroundImage: `url(${HomeBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  image: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    margin: theme.spacing(16, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(14, 6),
    },
  },
  login: {
    background: "rgba(0, 0, 0, 0.7)",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    padding: ".5rem",
    width: "13em",
    fontWeight: "bolder",
    borderRadius: "10px",
    background: "#dcb940",
    color: "#1d1d1b",
  },
  container__input_root: {
    borderRadius: "10px",
    '&:hover $notchedOutline': {
      borderColor: 'orange'
    },
    color:"white"
  },
  containerLogo: {
    margin: "-3em 0 3em 0",
  },
  textField: {
    margin: "11em 1em 0 1em",
    color: theme.palette.background.main,
    fontWeight: "bolder",
    fontSize: "42px",
    textShadow: "2px 2px 2px black",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToprops = {
  loginRequest,
  setPermissions,
  setToken,
};

export default connect(mapStateToProps, mapDispatchToprops)(SignIn);
