import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { setBreadcrumps } from "../../actions";
import { encrypt } from "../../utils/crypt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import Table from "../../components/Table";
import Header from "../../components/Header";
import axios from "../../api";
import Backdrop from "../../components/Backdrop";
import DeleteIcon from "@material-ui/icons/HighlightOff";

function ProgramList(props) {
  const { page, rowsPerPage, setBreadcrumps, permission, token } = props;
  const history = useHistory();
  const [programs, setPrograms] = useState([]);
  const [filtro, setFiltro] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (permission.includes(1)) {
      getProgram();
      setBreadcrumps([{ name: "Egresados" }, { name: "Programas" }]);
    } else {
      history.push("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProgram = async () => {
    setLoading(true);
    const { data } = await axios.post(
      `/program/getPrograms`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setPrograms(data.programs);
    setFiltro(data.programs);
    setLoading(false);
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case "edit":
        history.push(`/programs/edit/${encrypt(id)}`);
        break;
      case "create":
        history.push(`/programs/create`);
        break;
      default:
        break;
    }
  };

  const dataToExcel = async () => {
    // eslint-disable-next-line array-callback-return
    setLoading(true);
    const arrayExcel = [];
    await filtro?.forEach((item) => {
      item?.titulos?.forEach((e) => {
        arrayExcel.push({
          "Id Programa": item.id || "",
          Nombre: item.nombre || "",
          "Id Titulo": e.id || "",
          "Nombre Titulo": e.nombre || "",
          "Código SNIES": item.codigo_snies,
          "Procódigo SNIES": item.procodigo_snies,
        });
      });
    });
    setLoading(false);
    return arrayExcel;
  };
  return (
    <Paper elevation={0} className="pw-2">
      <Header
        search={false}
        button={permission.includes(2) ? true : false}
        exportButton={permission.includes(5) ? true : false}
        dataToExcel={{ fileName: "Programas" }}
        dataToExcelF={dataToExcel}
        buttonText={"Crear"}
        buttonRoute={"/programs/create"}
        tableName={"programs"}
        items={programs}
        setItems={setFiltro}
      />
      <Table columns={columns} rows={filtro} total={programs.length}>
        <TableBody>
          {filtro?.length > 0 ? (
            <>
              {filtro
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`row${row.id}`}>
                    <TableCell align="center">
                      {permission.includes(3) && (
                        <Tooltip title="Editar">
                          <IconButton
                            aria-label="edit"
                            onClick={(e) => handleClick(e, row.id, "edit")}
                          >
                            <FontAwesomeIcon icon={faEdit} size={"xs"} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {permission.includes(4) && (
                        <Tooltip title="Eliminar">
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => handleClick(e, row.id, "delete")}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="center">{row?.nombre}</TableCell>
                    <TableCell align="center">{row?.codigo_snies}</TableCell>
                    <TableCell align="center">{row?.procodigo_snies}</TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="15">
                No hay datos registrados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Backdrop loading={loading} />
    </Paper>
  );
}

const columns = [
  {
    id: "acciones",
    label: "Acciones",
    align: "center",
    minWidth: 10,
    colSpan: 2,
  },
  {
    id: "name",
    label: "Nombre",
    minWidth: 100,
    align: "center",
  },
  {
    id: "sniescode",
    label: "Código SNIES",
    minWidth: 150,
    align: "center",
  },
  {
    id: "procodesnies",
    label: "Procódigo SNIES",
    minWidth: 150,
    align: "center",
  },
];

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 8)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToProps = {
  setBreadcrumps,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramList);
