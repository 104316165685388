import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { setBreadcrumps, setPage } from "../../actions";
import Table from "../../components/Table";
import Header from "../../components/Header";
import axios from "../../api";
import Backdrop from "../../components/Backdrop";
import Cancel from "@material-ui/icons/Cancel";
import Approve from "@material-ui/icons/CheckCircle";
import Swal from "sweetalert2";

function RequestsList(props) {
  const { page, rowsPerPage, setBreadcrumps, permission, token, setPage } =
    props;
  const history = useHistory();
  const [certificates, setGraduated] = useState([]);
  const [filtro, setFiltro] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (permission.includes(1)) {
      getRequests();
      setBreadcrumps([
        { name: "Egresados" },
        { name: "Solicitudes de certificados" },
      ]);
    } else {
      history.push("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRequests = async () => {
    setLoading(true);
    const { data } = await axios.post(
      `/certificate/getCertificates`,
      { vp_estado_solicitud: 229 },
      {
        headers: { "access-token": token },
      }
    );
    setGraduated(data.certificates);
    setFiltro(data.certificates);
    setTotal(data.certificates.length);
    setLoading(false);
  };

  const updateRequest = async (id, status) => {
    setLoading(true);
    const { data } = await axios.put(
      `/certificate/${id}`,
      { vp_estado_solicitud: status },
      {
        headers: { "access-token": token },
      }
    );
    if (data?.certificateId) {
      Swal.fire({
        icon: "success",
        text: "Se actualizó correctamente el estado de la solicitud",
      });
    } else {
      Swal.fire({ icon: "error", text: "Error al actualizar estado" });
    }

    getRequests();
    setPage(0);
    setLoading(false);
  };

  return (
    <Paper elevation={0} className="pw-2">
      <Header
        tableName={"certificates"}
        items={certificates}
        setItems={setFiltro}
      />
      <Table columns={columns} rows={filtro} count={total}>
        <TableBody>
          {filtro?.length > 0 ? (
            <>
              {filtro
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`row${row.id}`}>
                    <TableCell align="center">
                      <Tooltip title="Aceptar solicitud">
                        <IconButton
                          aria-label="read"
                          onClick={(e) => updateRequest(row.id, 230)}
                        >
                          <Approve />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Rechazar solicitud">
                        <IconButton
                          aria-label="read"
                          onClick={(e) => updateRequest(row.id, 231)}
                        >
                          <Cancel />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      {row?.Egresado.egresados.identificacion}
                    </TableCell>
                    <TableCell align="center">
                      {`${row?.Egresado.egresados.primer_nombre} ${row?.Egresado.egresados.segundo_nombre}`.trim()}
                    </TableCell>
                    <TableCell align="center">
                      {`${row.Egresado.egresados.primer_apellido} ${row?.Egresado.egresados.segundo_apellido}`.trim()}
                    </TableCell>
                    <TableCell align="center">
                      {row?.Egresado.Titulo.nombre}
                    </TableCell>
                    <TableCell align="center">
                      {row?.createdAt.substring(0, 10)}
                    </TableCell>
                    <TableCell align="center">
                      {`${row?.Solicitante.nombres} ${row?.Solicitante.apellidos} `}
                    </TableCell>
                  </TableRow>
                ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="15">
                No hay datos registrados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Backdrop loading={loading} />
    </Paper>
  );
}

const columns = [
  {
    id: "acciones",
    label: "Acciones",
    align: "center",
    minWidth: 10,
    colSpan: 2,
  },
  {
    id: "document",
    label: "Documento",
    minWidth: 100,
    align: "center",
  },
  {
    id: "name",
    label: "Nombres",
    minWidth: 150,
    align: "center",
  },
  {
    id: "lastname",
    label: "Apellidos",
    minWidth: 150,
    align: "center",
  },
  {
    id: "title",
    label: "Título",
    minWidth: 100,
    align: "center",
  },
  {
    id: "requestdate",
    label: "Fecha de solicitud",
    minWidth: 100,
    align: "center",
  },
  {
    id: "requester",
    label: "Solicitante",
    minWidth: 100,
    align: "center",
  },
];

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 9)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToProps = {
  setBreadcrumps,
  setPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestsList);
